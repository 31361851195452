const queryKeysSchema = [
  'authenticationMethods',
  'bot',
  'botRun',
  'botRunLogFile',
  'botRuns',
  'bots',
  'branding',
  'companies',
  'companiesCsv',
  'companiesCountCsv',
  'companiesNiceClassesCsv',
  'companiesRegistrationsCsv',
  'company',
  'companyStore',
  'companyStores',
  'companyStoresCsv',
  'companyTrademarks',
  'companyUsers',
  'channels',
  'countries',
  'currency',
  'currencies',
  'dataProvider',
  'dataProviderSubdomains',
  'dataProviders',
  'enforceableAreas',
  'enforcement',
  'enforcementActionTypes',
  'enforcementAuditTrails',
  'enforcementComments',
  'enforcements',
  'enforcementsCsv',
  'enforcementStatuses',
  'enforcementTypes',
  'keymetrics',
  'keyword',
  'keywordAssociatedBots',
  'keywords',
  'listing',
  'listings',
  'listingsCsv',
  'niceClass',
  'niceClasses',
  'notificationTemplate',
  'notificationTemplates',
  'reports',
  'report',
  'reseller',
  'resellers',
  'settingsChannel',
  'settingsChannels',
  'store',
  'stores',
  'storesCsv',
  'storeBusinessTypes',
  'subdomain',
  'subdomainDataProvider',
  'subdomains',
  'testPurchase',
  'testPurchases',
  'testPurchasesCsv',
  'testPurchaseStatuses',
  'trademark',
  'trademarkOffice',
  'trademarkOffices',
  'trademarkType',
  'trademarkTypes',
  'user',
  'userRoles',
  'loa',
  'loas',
  'brand',
  'brands',
] as const;

type QueryKeysType = typeof queryKeysSchema[number];

const createObjectFromKeys = <T extends QueryKeysType>(arr: readonly T[]) =>
  arr.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {} as Record<T, T>);

export const QUERY_KEYS = createObjectFromKeys(queryKeysSchema);
