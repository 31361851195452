import classNames from 'classnames/bind';
import { ReactNode, useEffect, useRef } from 'react';

import { Button } from '@/components/shared/buttons';
import { Body } from '@/components/shared/typography/Body';
import { Loader } from '@/components/shared/loaders/Loader';
import { useMediaQuery } from '@/hooks/shared';

import style from './TabControl.module.sass';

const cx = classNames.bind(style);

export type TabControlProps = {
  isActive: boolean;
  handleClick: () => void;
  label: ReactNode;
  isLoading?: boolean;
  variant?: 'primary' | 'secondary';
};

export const TabControl = ({
  isActive,
  handleClick,
  label,
  isLoading,
  variant = 'primary',
}: TabControlProps) => {
  const tabRef = useRef<HTMLButtonElement>(null);
  const isTablet = useMediaQuery('(max-width: 1280px)');

  const tabControlClassName = cx('tabControl', variant, {
    isActive,
    isLoading,
  });

  useEffect(() => {
    if (!isTablet) {
      return;
    }

    const currentElement = tabRef?.current;

    if (isActive && currentElement) {
      setTimeout(() => {
        if (typeof currentElement.scrollIntoView === 'function') {
          currentElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }, 0);
    }
  }, [
    isActive,
    isTablet,
  ]);

  return (
    <Button.Unstyled
      ref={tabRef}
      onClick={handleClick}
      className={tabControlClassName}>
      <Body size='medium'>
        {label}
      </Body>
      {
        isLoading &&
        <span className={style.loaderWrapper}>
          <Loader size='small' />
        </span>
      }
    </Button.Unstyled>
  );
};
