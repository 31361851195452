import { getSettingsRoute } from '@/utils/routes/setting';
import { RouteParam } from '@/types/shared';

export const getTrademarkOfficesRoute = () => {
  return `${getSettingsRoute()}/trademark-offices`;
};

export const getTrademarkOfficeRoute = (id: RouteParam) => {
  return `${getTrademarkOfficesRoute()}/${id}`;
};

export const getDeleteTrademarkOfficeRoute = (id: RouteParam) => {
  return `${getTrademarkOfficeRoute(id)}/delete`;
};

export const getCreateTrademarkOfficeRoute = () => {
  return `${getTrademarkOfficesRoute()}/create`;
};
export const getAddChannelsSubdomainsTrademarkOfficeRoute = (id: RouteParam) => {
  return `${getTrademarkOfficeRoute(id)}/add-channels-subdomains`;
};

export const getEditTrademarkOfficeRoute = (id: RouteParam) => {
  return `${getTrademarkOfficeRoute(id)}/edit`;
};
