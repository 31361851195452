import { RouteParam } from '@/types/shared';

export const getSettingsRoute = () => {
  return '/settings';
};

export const getSettingsChannelsRoute = () => {
  return `${getSettingsRoute()}/channels`;
};

export const getCreateChannelRoute = () => {
  return `${getSettingsChannelsRoute()}/create`;
};

export const getSettingsChannelRoute = (channelId: RouteParam) => {
  return `${getSettingsChannelsRoute()}/${channelId}`;
};

export const getEditSettingChannelRoute = (channelId: RouteParam) => {
  return `${getSettingsChannelRoute(channelId)}/edit`;
};

export const getDeleteSettingChannelRoute = (channelId: RouteParam) => {
  return `${getSettingsChannelRoute(channelId)}/delete`;
};
