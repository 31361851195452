import classNames from 'classnames/bind';
import { useRef } from 'react';

import {
  Body,
  type BodyProps,
} from '@/components/shared/typography/Body';
import { Tooltip, type TooltipProps } from '@/components/shared/Tooltip';
import { useIsTruncated } from '@/hooks/dom';

import style from './MultilineBody.module.sass';

type MultilineBodyProps = BodyProps & {
  ellipsisVariant?: 'oneLine' | 'doubleLine' | 'tripleLine' | 'quadrupleLine';
  content?: string;
  tooltipSizeVariant?: TooltipProps['sizeVariant'];
};

const cx = classNames.bind(style);

export const MultilineBody = ({
  className,
  size,
  children,
  content,
  ellipsisVariant = 'oneLine',
  tooltipSizeVariant,
}: MultilineBodyProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isTruncated = useIsTruncated(ref);

  const bodyClassNames = cx('body', className, ellipsisVariant);

  return (
    <Tooltip
      content={
        <span className={style.content}>
          {content ?? children}
        </span>
      }
      disabled={!isTruncated}
      sizeVariant={tooltipSizeVariant}
      delay={[400, 100]}>
      <span>
        <Body size={size}>
          <div
            ref={ref}
            className={bodyClassNames}>
            {children}
          </div>
        </Body>
      </span>
    </Tooltip>
  );
};
