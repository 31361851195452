import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {
  RefObject,
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import {
  Breadcrumb,
  BreadcrumbWithPersistedQueryString,
} from '@/components/shared/Breadcrumbs/Breadcrumb';
import { useBreadcrumbsConfig } from '@/components/shared/Breadcrumbs/utils';
import { BREADCRUMBS_INITIAL_DELAY } from '@/components/App/AppBreadcrumbs';
import { BreadcrumbContext } from '@/contexts/breadcrumb';
import { useCompany } from '@/hooks/company';
import { useUser } from '@/hooks/companyUser';
import { useCompanyTrademark } from '@/hooks/trademark';
import { useBranding } from '@/hooks/branding';
import { useBot } from '@/hooks/bot';
import { useBotRun } from '@/hooks/bot';
import { useReseller } from '@/hooks/resellers';
import { useCompanyStore, useStore } from '@/hooks/stores';
import { useListing } from '@/hooks/listing/useListing';
import { useSettingsChannel } from '@/hooks/settingChannel';
import { useDataProvider } from '@/hooks/dataProvider';
import { useSubdomain } from '@/hooks/subdomain';
import { useNotificationTemplate } from '@/hooks/notificationTemplate';
import { useKeyword } from '@/hooks/keyword';
import { useIsOnInstance } from '@/hooks/shared';
import { useReport } from '@/hooks/report';
import { useTrademarkOffice } from '@/hooks/trademarkOffice';
import { useLoa } from '@/hooks/loa';
import { useBrand } from '@/hooks/brand';
import type { BreadcrumbDataWithCustom } from '@/types/shared';

import style from './Breadcrumbs.module.sass';

const CompanyBreadcrumb = () => {
  const { companyId } = useParams();
  const { company } = useCompany(companyId);

  if (!company) {
    return null;
  }

  return <>{company.attributes.name}</>;
};

const UserBreadcrumb = () => {
  const { userId } = useParams();
  const { user } = useUser(userId);

  if (!user) {
    return null;
  }

  const {
    firstName,
    lastName,
  } = user.attributes;

  return <>{`${firstName} ${lastName}`}</>;
};

const TrademarkBreadcrumb = () => {
  const {
    trademarkId,
  } = useParams();

  const { trademark } = useCompanyTrademark(trademarkId);

  if (!trademark) {
    return null;
  }

  return <>{trademark.attributes.name}</>;
};

const BotBreadcrumb = () => {
  const { botId } = useParams();
  const { bot } = useBot(botId);

  if (!bot) {
    return null;
  }

  return <>{bot.attributes.name}</>;
};

const BotRunBreadcrumb = () => {
  const {
    botId,
    botRunId,
  } = useParams();
  const { t } = useTranslation();
  const { botRun } = useBotRun(botId, botRunId);

  if (!botRun) {
    return null;
  }

  return <>{t('bot:run.run')} {botRunId}</>;
};

const ResellerBreadcrumb = () => {
  const { resellerId } = useParams();
  const { reseller } = useReseller(resellerId);

  if (!reseller) {
    return null;
  }

  return <>{reseller.attributes.name}</>;
};

const StoreBreadcrumb = () => {
  const { isAdminInstance } = useIsOnInstance();

  if (isAdminInstance) {
    return (
      <AdminStoreBreadcrumb />
    );
  }

  return (
    <CustomerStoreBreadcrumb />
  );
};

const CustomerStoreBreadcrumb = () => {
  const { t } = useTranslation();

  const { storeId } = useParams();
  const { store } = useCompanyStore(storeId);

  if (!store) return null;

  return <>{store.attributes.name || t('common:unknown')}</>;
};

const AdminStoreBreadcrumb = () => {
  const { t } = useTranslation();

  const { storeId } = useParams();
  const { store } = useStore(storeId);

  if (!store) return null;

  return <>{store.attributes.name || t('common:unknown')}</>;
};

const ListingBreadcrumb = () => {
  const { listingId } = useParams();
  const { listing } = useListing(listingId);

  if (!listing) {
    return null;
  }

  return <>{listing.attributes.productName}</>;
};

const TestPurchaseBreadcrumb = () => {
  const { t } = useTranslation();
  const { testPurchaseId: id } = useParams();

  if (!id) return null;

  return <>{t('navigation:breadcrumbs.testPurchase', { id })}</>;
};

const EnforcementBreadcrumb = () => {
  const { t } = useTranslation();
  const { enforcementId: id } = useParams();

  if (!id) return null;

  return <>{t('navigation:breadcrumbs.enforcement', { id })}</>;
};

const SettingsChannelBreadcrumb = () => {
  const { channelId } = useParams();
  const { settingsChannel } = useSettingsChannel(channelId);

  if (!channelId) return null;

  return <>{settingsChannel?.attributes.name}</>;
};

const DataProviderBreadcrumb = () => {
  const { dataProviderId } = useParams();
  const { dataProvider } = useDataProvider(dataProviderId);

  if (!dataProviderId) return null;

  return <>{dataProvider?.attributes.name}</>;
};

const SettingsChannelSubdomainBreadcrumb = () => {
  const { subdomainId } = useParams();
  const {
    subdomain,
  } = useSubdomain(subdomainId);

  return <>{subdomain?.attributes.name}</>;
};

const NotificationTemplateBreadcrumb = () => {
  const {
    companyId,
    notificationTemplateId,
  } = useParams();

  const {
    branding,
  } = useBranding();

  const companyIdParam = companyId || branding?.attributes.companyId;

  const { notificationTemplate } = useNotificationTemplate(notificationTemplateId, companyIdParam);

  if (!notificationTemplate) {
    return null;
  }

  return <>{notificationTemplate.attributes.name}</>;
};

const KeywordBreadcrumb = () => {
  const { companyId, keywordId } = useParams();

  const {
    branding,
  } = useBranding();
  const companyIdParam = companyId || branding?.attributes.companyId;

  const {
    keyword,
  } = useKeyword(companyIdParam, keywordId);

  return <>{keyword?.attributes.phrase}</>;
};

const ReportBreadcrumb = () => {
  const {
    reportId,
  } = useParams();

  const {
    report,
  } = useReport(reportId);

  return <>{report?.attributes.name}</>;
};

const TrademarkOfficeBreadcrumb = () => {
  const {
    trademarkOfficeId,
  } = useParams();

  const {
    trademarkOffice,
  } = useTrademarkOffice(trademarkOfficeId);

  return <>{trademarkOffice?.attributes.name}</>;
};

const LoaBreadcrumb = () => {
  const { companyId, loaId } = useParams();
  const { branding } = useBranding();

  const companyIdParam = companyId || branding?.attributes.companyId;

  const {
    loa,
  } = useLoa(companyIdParam, loaId);

  return <>{loa?.attributes.description}</>;
};

const BrandBreadcrumb = () => {
  const { brandId } = useParams();
  const {
    brand,
  } = useBrand(brandId);

  return <>{brand?.attributes.name}</>;
};

export const Breadcrumbs = () => {
  const breadcrumbsConfig = useBreadcrumbsConfig({
    components: {
      CompanyBreadcrumb,
      UserBreadcrumb,
      TrademarkBreadcrumb,
      BotBreadcrumb,
      BotRunBreadcrumb,
      ResellerBreadcrumb,
      StoreBreadcrumb,
      ListingBreadcrumb,
      TestPurchaseBreadcrumb,
      EnforcementBreadcrumb,
      SettingsChannelBreadcrumb,
      DataProviderBreadcrumb,
      SettingsChannelSubdomainBreadcrumb,
      NotificationTemplateBreadcrumb,
      KeywordBreadcrumb,
      ReportBreadcrumb,
      TrademarkOfficeBreadcrumb,
      LoaBreadcrumb,
      BrandBreadcrumb,
    },
  });

  const breadcrumbs: BreadcrumbDataWithCustom[] = useBreadcrumbs(breadcrumbsConfig, {
    disableDefaults: true,
  });

  const breadcrumbRefs = useRef<(RefObject<HTMLSpanElement> | null)[]>([]);
  breadcrumbRefs.current = breadcrumbs.map((_, i) => breadcrumbRefs.current[i] ?? createRef());

  const [allBreadcrumbsLoaded, setAllBreadcrumbsLoaded] = useState(false);
  const { isFirstRender } = useContext(BreadcrumbContext);
  const { current: shouldAnimateBreadcrumbs } = useRef(isFirstRender) ?? {};

  useEffect(() => {
    const checkBreadcrumbs = () => {
      const allLoaded = breadcrumbRefs.current.every(
        (ref) => ref?.current && ref.current.textContent,
      );

      setAllBreadcrumbsLoaded(allLoaded);

      if (!allLoaded) {
        setTimeout(checkBreadcrumbs, BREADCRUMBS_INITIAL_DELAY);
      }
    };
    checkBreadcrumbs();
  }, [breadcrumbs]);

  const renderBreadcrumb = (data: BreadcrumbDataWithCustom, i: number) => {
    const commonProps = {
      href: data.customHref || data.key,
      iconName: data.iconName,
      children: (
        <span ref={breadcrumbRefs.current[i]}>
          {data.breadcrumb}
        </span>
      ),
    };

    if (data.persistedQueryStringKey) {
      return (
        <BreadcrumbWithPersistedQueryString
          {...commonProps}
          key={data.key}
          match={data.match}
          persistedQueryStringKey={data.persistedQueryStringKey}
        />
      );
    }

    if (data.isDisabled) {
      const isBreadcrumbDisabled = typeof data.isDisabled === 'function'
        ? data.isDisabled(data.match)
        : data.isDisabled;

      return (
        <Breadcrumb
          {...commonProps}
          key={data.key}
          isDisabled={isBreadcrumbDisabled}
        />
      );
    }

    return (
      <Breadcrumb
        key={data.key}
        {...commonProps}
      />
    );
  };

  if (shouldAnimateBreadcrumbs) {
    return (
      <motion.div
        className={style.wrapper}
        initial={{ opacity: 0 }}
        animate={{ opacity: allBreadcrumbsLoaded ? 1 : 0 }}>
        {breadcrumbs.map(renderBreadcrumb)}
      </motion.div>
    );
  }

  return (
    <div className={style.wrapper}>
      {breadcrumbs.map(renderBreadcrumb)}
    </div>
  );
};
